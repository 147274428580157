import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Start = _resolveComponent("Start")!
  const _component_Popular = _resolveComponent("Popular")!
  const _component_Tournament = _resolveComponent("Tournament")!
  const _component_Result = _resolveComponent("Result")!

  return (_ctx.state === 'home')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Start, {
          pos: _ctx.pos,
          places: _ctx.places,
          state: _ctx.state,
          zip: _ctx.zip,
          onUpdateOption1: _ctx.updateOption1,
          onUpdateOption2: _ctx.updateOption2,
          onUpdatePos: _ctx.updatePos,
          onUpdatePlaces: _ctx.updatePlaces,
          onUpdatePopular: _ctx.updatePopular,
          onUpdateState: _ctx.updateState,
          onUpdateZip: _ctx.updateZip
        }, null, 8, ["pos", "places", "state", "zip", "onUpdateOption1", "onUpdateOption2", "onUpdatePos", "onUpdatePlaces", "onUpdatePopular", "onUpdateState", "onUpdateZip"])
      ]))
    : (_ctx.state === 'popular')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Popular, {
            final: _ctx.final,
            places: _ctx.places,
            popular: _ctx.popular,
            state: _ctx.state,
            zip: _ctx.zip,
            onUpdateFinal: _ctx.updateFinal,
            onUpdateState: _ctx.updateState
          }, null, 8, ["final", "places", "popular", "state", "zip", "onUpdateFinal", "onUpdateState"])
        ]))
      : (_ctx.state === 'tournament')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Tournament, {
              option1: _ctx.option1,
              option2: _ctx.option2,
              places: _ctx.places,
              state: _ctx.state,
              zip: _ctx.zip,
              onUpdateFinal: _ctx.updateFinal,
              onUpdateState: _ctx.updateState
            }, null, 8, ["option1", "option2", "places", "state", "zip", "onUpdateFinal", "onUpdateState"])
          ]))
        : (_ctx.state === 'result')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Result, { final: _ctx.final }, null, 8, ["final"])
            ]))
          : _createCommentVNode("", true)
}