
import { defineComponent } from "vue";
export default defineComponent({
    data() {
        return {
            map: (window as any).google.maps,
        };
    },
    props: {
        final: {
            type: Object,
            required: true,
        },
    },
    name: "Result",
    mounted() {
        let map = new (window as any).google.maps.Map(
            document.getElementById("result-map"),
            {
                center: { lat: this.final.Lat, lng: this.final.Lng },
                zoom: 18,
            }
        );
        this.map = map;
    },
});
