
import { defineComponent } from "vue";
import Start from "../components/Start.vue";
import Popular from "../components/Popular.vue";
import Tournament from "../components/Tournament.vue";
import Result from "../components/Result.vue";

export default defineComponent({
    data() {
        return {
            final: {},
            option1: "",
            option2: "",
            places: {},
            popular: {},
            pos: {
                lat: 0.0,
                lng: 0.0,
            },
            state: "home",
            zip: "-1",
        };
    },
    name: "Top",
    components: {
        Start: Start,
        Popular: Popular,
        Tournament: Tournament,
        Result: Result,
    },
    methods: {
        updateFinal(final: any): void {
            this.final = final;
        },
        updateOption1(option1: string): void {
            this.option1 = option1;
        },
        updateOption2(option2: string): void {
            this.option2 = option2;
        },
        updatePlaces(places: any): void {
            this.places = places;
        },
        updatePopular(popular: any): void {
            this.popular = popular;
        },
        updatePos(pos: { lat: number; lng: number }): void {
            this.pos = pos;
        },
        updateState(state: string): void {
            this.state = state;
        },
        updateZip(zip: string): void {
            this.zip = zip;
        },
    },
    //Bind window resize event
    //window.addEventListener("resize", () => {});
});
